import request from '@/utils/request'


// 查询用户信息列表
export function listUser(query) {
  return request({
    url: '/biz/users/list',
    method: 'get',
    params: query
  })
}

// 查询用户信息分页
export function pageUser(query) {
  return request({
    url: '/biz/users/getUserTotal',
    method: 'get',
    params: query
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/biz/users/changeIsOpen',
    method: 'post',
    params: data
  })
}




export function getVideoTotalData(query) {
  return request({
    url: '/video/videos/getVideoTotalData',
    method: 'get',
    params: query
  })
}

export function getUserTotalData(query) {
  return request({
    url: '/biz/users/getUserTotalData',
    method: 'get',
    params: query
  })
}


// 查询用户信息详细
export function getUser(data) {
  return request({
    url: '/biz/users/detail',
    method: 'get',
    params: data
  })
}

// 新增用户信息
export function addUser(data) {
  return request({
    url: '/biz/users/add',
    method: 'post',
    data: data
  })
}

// 修改用户信息
export function updateUser(data) {
  return request({
    url: '/biz/users/edit',
    method: 'post',
    data: data
  })
}

// 删除用户信息
export function delUser(data) {
  return request({
    url: '/biz/users/delete',
    method: 'post',
    data: data
  })
}





//
// // 统计（机构/门店）
// export function getALLCollectByEcharts (query) {
//   return request({
//     url: '/platform/platform-institution/getALLCollectByEcharts',
//     method: 'get',
//     params: query
//   })
// }
//
// // 统计（用户总数量/日增数量）
// export function getALLAppUserByEcharts (query) {
//   return request({
//     url: '/platform/platform-institution/getALLAppUserByEcharts',
//     method: 'get',
//     params: query
//   })
// }
//
// // 统计（设备总数量/日增数量）
// export function getALLDeviceByEcharts (query) {
//   return request({
//     url: '/platform/platform-institution/getALLDeviceByEcharts',
//     method: 'get',
//     params: query
//   })
// }
